import { LoadingService } from './core/loading.service';
import { ManagerLoginPage } from './modals/manager-login/manager-login.page';
import { DataService } from './data.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { Component, OnInit } from '@angular/core';

import { Platform, MenuController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { Network } from '@ionic-native/network/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { LogService } from './core/log.service';
import { filter } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { DatabaseService } from './database.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  
  public currentPage;

  showMenu = false;

  constructor(
    public ds: DataService,
    private db: DatabaseService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navBar: NavigationBar,
    private screenOrientation: ScreenOrientation,
    private authService: AuthService,
    private router: Router,
    private menuCtrl: MenuController,
    private androidFullScreen: AndroidFullScreen,
    private network: Network,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private loader: LoadingService,
    private appVersion: AppVersion,
    private route: ActivatedRoute,
    private log: LogService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async() => {
      
        //get the device UUID first
        await this.ds.getUniqueDeviceID();

        //initialize form
        await this.log.initialize().then(()=>{
            this.log.add('app_initialized', {})
        })
    
        
        //transfer stored and queued forms to sqlite db
        this.db.initialize().then(async(res:any)=>{
            if(!res.success) this.log.add("database_initialization_fail", {result: res});

            let maxFormAge = await this.ds.get("store/getMaxFormAge").then((res:any)=>res.max);
            let minimum = DateTime.now().minus({days: maxFormAge})
            let minTimestamp = Math.round(minimum.valueOf() / 1000);

            let syncedForms = [];
            let queuedForms:any = await this.ds.getStorage("queuedForms").then((res)=>res?res:[])
            let storedForms:any = await this.ds.getStorage("storedForms").then((res)=>res?res:[])
            let combined = storedForms.concat(queuedForms);
            
            
            for(let f of combined) {
                if(f.timestamp <= minTimestamp) {
                    console.log('skipping importing form', f)
                    continue;
                }

                //fetch store number from associate page so it gets tracked in the DB correctly
                if(!f.store_number)
                    for(let row of f.associateArr)
                        for(let field of row)
                            if(field.type === "associate_storenumber") f.store_number = field.answer;

                
                let form_numbers = await this.db.loadStoreFormNumbers(f.store_number);
    
                console.log("Checking if form #"+f.form_number+" is in the stored forms for store "+f.store_number, form_numbers);

                if(form_numbers.includes(f.form_number)) console.log('dont save - form #'+f.form_number+' already exists for store '+f.store_number)
                else {
                    syncedForms.push({
                        store_number: f.store_number,
                        form_number: f.form_number
                    });
                    await this.db.saveSubmission(f)
                }
            }

            if(syncedForms.length > 0) {
                console.log('Forms were synced to SQLITE', syncedForms);
                this.log.add('internally_synced_forms', {syncedForms})
            }
        })
      

        if(this.platform.is('cordova')) {
            this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
            this.statusBar.hide();
            this.statusBar.overlaysWebView(false);
            this.navBar.hideNavigationBar()

            this.androidFullScreen.isImmersiveModeSupported().then(()=>this.androidFullScreen.immersiveMode())
        }


        this.ds.getStorage('trainingMode').then((trainingMode)=>{
            if(trainingMode && trainingMode === 'true') {
                if(this.ds.debug) console.log('Enabling Training Mode')
                this.ds.trainingMode = true;
            }
            else this.ds.trainingMode = false;
        }).catch(()=>this.ds.trainingMode = false);

        
        this.ds.online = this.network.type === 'none' ? false : true;


        await this.ds.getStorage('storeNumber').then((store_number)=>{
            if(store_number) this.ds.store_number = store_number;
        })
        

        this.ds.getPermission();





      this.ds.getStorage('pageContent').then((content)=>{
        if(content) {
          this.ds.pageContent = content;
          
          //network connection needs error messages, so must be done after loading content from storage

          //network connection
          if(this.network.type === 'none') this.presentToast(this.ds.pageContent['app-menu']['no_connection_error']);

          //watch for disconnect
          this.network.onDisconnect().subscribe(() => {
            this.ds.online = false;
            this.ds.connected = false;
            this.presentToast(this.ds.pageContent['app-menu']['network_disconnected_message']);
          });

          // watch network for a connection
          this.network.onConnect().subscribe(() => {
            setTimeout(() => { //set timeout to leave enough time to confirm the connection
              if (this.network.type === 'wifi') {
                this.ds.online = true;
                if(this.ds.master_domain) {
                  this.ds.get('page/health').then((data:any)=> {
                    this.ds.connected = data && data.success === true;
                  }).catch(()=>this.ds.connected = false)
                }
                this.presentToast(this.ds.pageContent['app-menu']['network_connected_message']);

                console.error('network connect')

                this.handleStoredForms().then(()=>
                  console.log("submitted queued forms")
                ).catch(()=>
                  this.presentToast(this.ds.pageContent['form']['form_submission_error'])
                )
              }
            }, 3000);
          });
        //end network connection
        }
        else {
          this.network.onDisconnect().subscribe(() => {
            this.ds.online = false;
            this.ds.connected = false;
          });
          this.network.onConnect().subscribe(() => {
            setTimeout(() => {
              this.ds.online = this.network.type === 'wifi';
              if(this.ds.master_domain) {
                this.ds.get('page/health').then((data:any)=> {
                  this.ds.connected = data && data.success === true;
                }).catch(()=>this.ds.connected = false)
              }
            }, 1000);
          });
          this.authService.authState$.next(false);
        }
      });//end getStorage pageContent



        //set up auth listener
        this.authService.getAuthStateObserver().subscribe(state => {
            if (state) {
                if(this.ds.master_domain) {
                    this.ds.get('page/health').then((data:any)=> {
                        this.ds.connected = data && data.success === true;
                    }).catch(()=>this.ds.connected = false)
                }

                this.ds.getStorage('current-view').then((viewType)=>{
                    let defaultPage = "associate-dashboard";
                    if(this.platform.is('cordova')) this.splashScreen.hide()

                    if(viewType === 'customer') {
                        this.ds.getStorage('activeForm').then((data:any)=>{
                            if(data != null) {
                                this.loader.present();
                                console.error('present loader')
                                
                                setTimeout(()=>{
                                    console.error('dismiss loader')
                                    this.router.navigate(['form/associate-form'])
                                    console.error('dismiss loader2')
                                    this.loader.dismiss();
                                }, 500);
                            }
                            else {
                                this.ds.setCurrentView('associate').then(()=>this.router.navigate([defaultPage]))
                            }
                        }).catch(()=>{
                            this.ds.setCurrentView('associate').then(()=>this.router.navigate([defaultPage]))
                        });
                    }
                    else {
                        this.ds.setCurrentView('associate').then(()=>this.router.navigate([defaultPage]));
                    }
                })
                .catch(()=>{
                    if(this.platform.is('cordova')) this.splashScreen.hide()
                    this.router.navigate(['store-login'])
                })
            }
            else {
                if(this.platform.is('cordova')) this.splashScreen.hide()
                this.router.navigate(['store-login']);
            }
        }); //end authService.getAuthStateObserver subscription


        if (this.platform.is('cordova')) {
            this.appVersion.getVersionNumber().then(v => {
                this.ds.app_version = v
                this.log.add("app_finish_initilization", {version: v})
            });
        }
        else {
            //hardcoded to always get newest data
            this.ds.app_version = '3.0.0';
        }

        this.log.add("automatic_data_dump", {})

        //get data dump from server
        this.ds.getDataDump().then((data_loaded)=>{
            this.log.add("fetched_data_dump", {type:"automatic", data_loaded});
        });
    });
  }


  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((e:any)=>{
      console.log('event change. Check for query params', e);

      const form_completed = this.route.snapshot.queryParamMap.get('form_completed');
      if(form_completed) this.handleStoredForms();

      if(e.url) {
        this.currentPage = e.url; 
        this.showMenu = e.url !== '/store-login';
      }
    });

    this.ds.openManagerModel$.subscribe(() => {
      this.openManagerLogin();
    });
    
    this.ds.closeManagerModal$.subscribe(() => {
      if(this.menuCtrl.isOpen()) this.menuCtrl.close();
    });
  }


  async clearOldStoredForms() {
    let maxFormAge = await this.ds.get("store/getMaxFormAge").then((res:any)=>res.max);
    let minimum = DateTime.now().minus({days: maxFormAge})
    let minTimestamp = Math.round(minimum.valueOf() / 1000);

    let result = await this.db.clearOldSubmissions(minTimestamp);

    console.log('deletion result', result);
    this.log.add("old_submissions_cleared", {result})
}


  handleStoredForms() {
    return new Promise(async (res, rej) => {
        if(this.ds.debug) console.log('handle stored forms');
        if(!this.ds.store_number) {
            if(this.ds.debug) console.log("Cancel handle stored forms - no store number detected")
            return false;
        }

        this.clearOldStoredForms();

        let number_submitted = 0
        let total_stored_count = await this.db.countAllSubmissions();
        let submissions:any = await this.db.loadUnsubmittedSubmissions();

        if(this.ds.debug) console.log('checking for unsubmitted forms', submissions);

        //don't do any more logic if there are no forms to submit
        if(submissions.length == 0) return res(true)

        this.log.add("handle_stored_forms", {count: submissions.length});
        
        for(let sub of submissions) {
            let response = await this.db.processForm(sub.form_number, sub.store_number)
            console.log('processed form response', response);

            if(response.submitted) {
                number_submitted++;
                await this.log.add("submitted_form", {...response})
            }
            else {
                await this.log.add("failed_form", {...response})
            }
        }

        await this.log.add("queue_auto_processed", {
            queued_forms: submissions.length, 
            submitted_forms: number_submitted,
            failed_forms: submissions.length - number_submitted,
            total_stored_count
        })

        res(true);
    })
  }


  logout() {
    this.menuCtrl.close().then(()=>{
      setTimeout(()=>this.authService.logout(),500)
    })
  }


  reloadLocalData() {
    this.clearOldStoredForms();

    this.handleStoredForms();
    
    this.ds.getDataDump(true).then((data_loaded)=>{
      this.log.add("fetched_data_dump", {type:"manual", data_loaded});
    });
  }


  async openManagerLogin() {
    this.menuCtrl.close();

    const modal = await this.modalCtrl.create({
      component: ManagerLoginPage,
      cssClass: 'manager-login',
    });

    modal.onDidDismiss().then(({data})=>{
      if(data && data.success) this.ds.startManagerMode();
      else if(this.ds.currentView === 'manager') this.ds.endManagerMode();
    })
    return await modal.present();
  }


  //open search active or search completed, ask for store number
  async openSearch(type) {
    this.menuCtrl.close();
    
    this.authService.storeLock()
    .then(()=>this.menuCtrl.close().then(()=>this.router.navigate(['/search-'+type])))
    .catch((error)=>{
      console.log('error',error)
      return false;
    })
  }

  async presentToast(message='') {
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }
}
