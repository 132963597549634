import { DataService } from './../../data.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  
  private login: UrlTree;
  private managerDash: UrlTree;
  private associateDash: UrlTree;

  private activeForm:boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private ds: DataService,
  ) {
    this.login = this.router.parseUrl('store-login');
    this.managerDash = this.router.parseUrl('manager-dashboard');
    this.associateDash = this.router.parseUrl('associate-dashboard');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    
    if (this.authService.isAuthenticated() && route.routeConfig.path !== 'store-login') {
        return true;
    }
    else if(this.authService.isAuthenticated() != null){
      if (route.routeConfig.path === 'store-login') return true;
      else return this.login;
    }
    else return false;
  }
}