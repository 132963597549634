import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { DateTime } from 'luxon';

import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  private db: SQLiteObject;

  constructor(
    private sqlite: SQLite,
    private ds: DataService
  ) { }



  async initialize() {
    let result = {
      success: null,
      error: null
    }

    await this.sqlite.create({
        name: 'clairesep.db',
        location: 'default'
    }).then(async (db: SQLiteObject)=>{
        this.db = db;
        
        await db.transaction(async (tx) => {
            //check if table exists, create if not
            await tx.executeSql(`CREATE TABLE IF NOT EXISTS submissions(
                form_number INT(5) NOT NULL,
                store_number CHAR(10) NOT NULL, 
                timestamp INT(20) NOT NULL,
                data LONGTEXT NOT NULL,
                submitted BOOL DEFAULT 'false' NOT NULL,
                submitted_datetime DATETIME,
                PRIMARY KEY ( form_number, store_number )
            )`, [], (tx, resultSet) => {
                console.log('create table success', resultSet)
                result.success = true;
            }, (tx, error) => {
                result.success = false;
                result.error = error;
                console.log('create table failed', error)
            })
        })
    }).catch((err)=>{
        console.log('error opening db', err)
    })
    return result;
  }



  async loadStoreFormNumbers(store_number) {
    let form_numbers = [];
    return this.db.transaction((tx) => {
        tx.executeSql("SELECT form_number FROM submissions WHERE store_number = ?", [store_number], (tx, resultSet) => {
            for(var x = 0; x < resultSet.rows.length; x++) {
                form_numbers.push(resultSet.rows.item(x).form_number);
            }
        },
         (tx, error) => {
            console.log('loadStoreFormNumbers SELECT error: ' + error.message);
        });
    }).then(()=>form_numbers)
  }


  async countAllSubmissions() {
    let result;
    return this.db.transaction((tx) => {
        tx.executeSql("SELECT COUNT(*) AS count FROM submissions", [], (tx, resultSet) => {
            result = resultSet.rows.item(0).count;
        },
        (tx, error)=>result = 0);
    }).then(()=>result)
  }


  async loadAllSubmissions() {
    let submissions = [];
    return this.db.transaction((tx) => {
        tx.executeSql("SELECT * FROM submissions", [], (tx, resultSet) => {
            for(var x = 0; x < resultSet.rows.length; x++) {
                let sub = resultSet.rows.item(x);
                submissions.push({...sub, data: JSON.parse(sub.data)});
            }
        },
         (tx, error) => {
            console.log('SELECT error: ' + error.message);
        });
    }).then(()=>submissions)
  }


  async loadUnsubmittedSubmissions() {
    let submissions = [];
    return this.db.transaction((tx) => {
        tx.executeSql("SELECT * FROM submissions WHERE submitted = ? order by timestamp desc limit 10", ["false"], (tx, resultSet) => {
            for(var x = 0; x < resultSet.rows.length; x++) 
                submissions.push(resultSet.rows.item(x));
        },
        (tx, error) => {
            console.log('SELECT error: ' + error.message);
        });
    }).then(()=>submissions)
  }


  async clearOldSubmissions(timestamp) {
    let response = {
        count: 0,
        deleted: 0,
        forms: [],
        success: null
    }

    return this.db.transaction(async (tx) => {
        await tx.executeSql("SELECT * FROM submissions WHERE timestamp < ?", [timestamp], (tx, resultSet) => {
            response.count = resultSet.rows.length;

            for(var x = 0; x < resultSet.rows.length; x++) 
                response.forms.push(resultSet.rows.item(x));
        })        

        await tx.executeSql("DELETE FROM submissions WHERE timestamp < ?", [timestamp], (tx, resultSet) => {
            response.success = true;
            response.deleted = resultSet.rowsAffected
        });
    }).then(()=>response)
  }


  async saveSubmission(submission) {
    let store_number = submission.store_number;
    let data = JSON.stringify(submission);
    let success = false;

    await this.db.transaction(async (tx) => {
        let values = [
            submission.form_number, 
            store_number, 
            submission.timestamp, 
            data,
            submission.submitted 
        ];
        
        await tx.executeSql(`INSERT INTO submissions (form_number, store_number, timestamp, data, submitted) VALUES(?,?,?,?,?)`, values, (tx, res) => {
            success = true;
        },
        (tx, error) => {
            success = false;
            //skip code where the submission already exists
            if(error.code != 6) console.log('INSERT error: ' + error.message, error);
        })
    }).then((res)=>{
        console.log('saveSubmission complete', submission);
    }).catch((err)=>{
        console.error('error storing submission', err)
    })
    return success;
  }


  formSubmitted(form_number, store_number) {
    this.db.transaction((tx) => {
        let datetime = DateTime.now().toFormat('yyyy-MM-dd H:mm:ss')
        let values = [true, datetime, form_number, store_number];
        tx.executeSql(`UPDATE submissions SET submitted = ?, submitted_datetime = ? WHERE form_number = ? and store_number = ?`, values, (tx, res) => {

        },
        (tx, error) => {
            console.log('UPDATE error: ' + error.message);
        })
    }).then((res)=>{
        console.log('formSubmitted transaction complete - form #'+form_number+" for store "+store_number+" marked as submitted");
    }).catch((err)=>{
        console.error('transaction error', err)
    })
  }

  async processForm(form_number, store_number, process="auto") {
    console.log("processing form #"+form_number+" from store "+store_number)
    let submission = null;
    let response = {
        submitted: false,
        duplicate: false,
        process,
        submission_id: null,
        success: null,
        form_response: null,
        image_response: null,
        image_success: null,
        image_submitted: null,
        image_error: null,
        server_error: null,
        endpoint_rejected: null,
    };

    //load the data from local DB first
    await this.db.transaction(async (tx) => {
        await tx.executeSql("SELECT * FROM submissions WHERE form_number = ? and store_number = ? ", [form_number, store_number], (tx, resultSet) => {
            submission = resultSet.rows.item(0);
            submission.data = JSON.parse(submission.data);
        },
         (tx, error) => {
            console.log('SELECT error: ' + error.message);
        });
    }).then(()=>{}).catch(()=>{})


    console.log('submission', submission);

    
    //attempt to submit form
    await this.ds.post('form/submit', {...submission.data, process}).then(async (formRes:any) => {
        delete formRes.debug;
        response.form_response = formRes;
        
        //if we receive a submission ID, attempt to upload the image
        if(formRes.submission_id) {
            await this.ds.uploadImage(formRes.submission_id, submission.data.imageData).then(async (imageRes:any)=>{
                delete imageRes.debug;
                response.image_response = imageRes;

                if(imageRes.success) {
                    response.image_submitted = true;
                    response.submitted = true;
                    this.formSubmitted(submission.form_number, submission.store_number);
                }
            }).catch((err)=>{
                response.image_submitted = false;
                response.image_error = err;
            });
        }
        //else if there is no submission ID, and success is false, it was rejected
        else if(!formRes.success) {
            response.endpoint_rejected = true;
        }
        //if duplicate (success is true and submission_id is false)
        else { 
            response.submitted = true;
            response.duplicate = formRes.message == "Duplicate submission" ? true : false
            this.formSubmitted(submission.form_number, submission.store_number)
        }
    }).catch(async (err)=>{
        response.server_error = err;
    });

    return response;
  }

}
