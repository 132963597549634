import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { OCR } from '@ionic-native/ocr/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Device } from '@ionic-native/device/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { SQLite } from '@ionic-native/sqlite/ngx';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { ManagerLoginPageModule } from './modals/manager-login/manager-login.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    NgxIonicImageViewerModule,
    ManagerLoginPageModule,
  ],
  providers: [
    StatusBar,
    NavigationBar,
    ScreenOrientation,
    SplashScreen,
    AndroidFullScreen,
    Network,
    Camera,
    CameraPreview,
    File,
    FileOpener,
    FileTransfer,
    AndroidPermissions,
    AppVersion,
    SQLite,
    OCR,
    Device,
    UniqueDeviceID ,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
