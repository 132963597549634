import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { DataService } from '../data.service';
import { DateTime } from "luxon"
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { HttpHeaders } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';


@Injectable({
  providedIn: 'root'
})

export class LogService {

    logPath = "";

  constructor(
      public loadingController: LoadingController,
      private ds: DataService,
      private file: File,
      private network: Network,
      private fileOpener: FileOpener,
      private platform: Platform
    ) { }


    async initialize() {
        let today = DateTime.now().toFormat('yyyy-MM-dd');
        console.warn('Log Initialize - Today:', today);

        this.logPath = this.file.externalDataDirectory+"claires_action_log/";

        let action_log = await this.ds.getStorage("action_log", false);
        if(!action_log) {
            action_log = {};
            action_log[today] = [];
            this.ds.setStorage("action_log", action_log, false);
        }


        if(this.platform.is('cordova')) {
            //check current archive list
            await this.file.checkDir(this.file.externalDataDirectory, 'claires_action_log')
            .then(async() => {
                await this.file.listDir(this.file.externalDataDirectory, 'claires_action_log').then(async(res) => {
                    console.warn('Claires Archived Action Log List', res)
    
                    //if there are more than 7 files stored locally
                    if(res.length > 7) {
                        let index = 0;
                        //loop through each file
                        for(let file of res) {
                            //fiels are sorted by date created. the first files are oldest
                            //if the index of the current file is less than the total length - 7, then delete it
                            if(index < res.length - 7) {
                                console.error("DELETE OLD FILE - ",file);
                                let entry:any = await this.file.resolveLocalFilesystemUrl(file.nativeURL)
                                entry.remove();
                            }
                            index++;
                        }
                    }
                    
                    /* UNCOMMENT THE BELOW TO CLEAR ALL LOCAL ARCHIVED LOGS - DEV ONLY */
                    // for(let file of res) {
                    //     let entry:any = await this.file.resolveLocalFilesystemUrl(file.nativeURL)
                    //     entry.remove();
                    // }
                }).catch(()=>[]);
            })
            .catch(err =>{
                this.file.createDir(`${this.file.externalDataDirectory}`,'claires_action_log', false)
            })
        }
    }
    
      
    async add(actionType, data) {
        let logItem = {
            data,
            connected_to_server: this.ds.online,
            network_type: this.network ? this.network.type : "Network Status Unknown",
            action_type: actionType,
            store_number: this.ds.store_number,
            device_id: this.ds.UniqueDeviceID,
            created_at: DateTime.now().toFormat('yyyy-MM-dd H:mm:ss'),
            created_at_gmt: DateTime.now().toUTC().toFormat('yyyy-MM-dd H:mm:ss')
        }
        let actionLog:any = await this.openFile();
        console.log('add action log item', logItem)
        actionLog.push(logItem);
        return this.save(actionLog);
    }


    async save(data, date:any=false) {
        if(!date) date = DateTime.now().toFormat('yyyy-MM-dd');
        let action_log:any = await this.ds.getStorage("action_log", false)
        action_log[date] = data;
        this.ds.setStorage("action_log", action_log, false);
        // this.file.writeExistingFile(this.file.externalDataDirectory, `claires_action_log/${date}_log.json`, JSON.stringify(data))
    }


    async load(actionType:any=false, date=false, newestFirst=true) {
        return new Promise(async (res, rej) => {
            let actionLog:any = await this.openFile(date)
            console.log('loaded', actionLog)
            if(actionType) {
                let filteredList = actionLog.filter((el)=>{
                    if(el.action_type === actionType) return el
                })
                console.error('log-load',filteredList)
                if(newestFirst) filteredList.reverse();
                res(filteredList);
            }
            else {
                if(newestFirst) actionLog.reverse();
                res(actionLog)
            }
        });
    }


    
    async checkFile(date, autoCreate=true) {
        if(this.platform.is('cordova')) {
            return this.file.checkFile(this.file.externalDataDirectory, `claires_action_log/${date}_log.json`).then((doesExist) => {
                console.warn("log exists for "+date)
            })
            .catch(async(err) => {
                console.warn("log does not exist for "+date)
                await this.file.createFile(this.file.externalDataDirectory, `claires_action_log/${date}_log.json`, false)
                await this.file.writeExistingFile(this.file.externalDataDirectory, `claires_action_log/${date}_log.json`, JSON.stringify([]))
            });
        }
    }


    //open previous log file
    async openFile(date:any = false) {
        if(!date) date = DateTime.now().toFormat('yyyy-MM-dd');
        let log = await this.ds.getStorage("action_log", false)
        if(log && log[date]) return log[date];
        return [];
        // return this.file.readAsText(this.file.externalDataDirectory+"claires_action_log", date+"_log.json").then((val) => JSON.parse(val))
    }

    async loadTypes(date=false) {
        if(!date) date = DateTime.now().toFormat('yyyy-MM-dd');
        return new Promise(async (res, rej) => {
            let actionLog:any = await this.openFile(date)
            let typeList = [];
            for(let log of actionLog) {
                if(!typeList.includes(log.action_type)) typeList.push(log.action_type);
            }
            res(typeList)
        });
    }
    
    async loadPastLogs() {
        let archived = await this.file.listDir(this.file.externalDataDirectory, 'claires_action_log').then(res => {
            console.warn('claires_action_log', res)
            return res;
        }).catch(()=>[]);

        let action_log = await this.ds.getStorage("action_log", false)
        console.log('loadPastLogs', archived, action_log)
        if(action_log) return Object.keys(action_log)
        else return [];
    }


    async formatFormLogData(form) {
      return new Promise((res, rej) => {
        let logData:any = {
          form_number: form.form_number,
          local_time: form.local_time
        };
    
        //format data as needed
        for(let s of form.associateArr) {
          for(let f of s) {
            if(f.type === 'associate_date') logData.date = f.answer;
            if(f.type === 'associate_number') logData.associate = f.answer;
            if(f.type === 'associate_storenumber') logData.store = f.answer;
            if(f.type === 'associate_sku') logData.sku = f.answer;
          }
        }
        for(let s of form.sectionArr) {
          for(let f of s) {
            if(f.type === 'customer_firstname') logData.first_name = f.answer;
            if(f.type === 'customer_lastname') logData.last_name = f.answer;
            if(f.type === 'customer_dob') logData.dob = f.answer;
            if(f.type === 'customer_address') logData.address = f.answer;
            if(f.type === 'customer_city') logData.city = f.answer;
            if(f.type === 'customer_state') logData.state = f.answer;
            if(f.type === 'customer_zip') logData.zip = f.answer;
            if(f.type === 'customer_email') logData.email = f.answer;
            if(f.type === 'customer_phone') logData.phone = f.answer;
          }
        }
        res(logData);
      })
    }


    async uploadLog(date) {
        return new Promise(async(res, rej) => {
            console.warn('Uploading log date', date)
            await this.checkFile(date)
            let data = await this.load(false, date);
            if(data) {
                console.warn('uploadLog loaded data', data)
                let writeFile:any = await this.file.writeExistingFile(this.file.externalDataDirectory, `claires_action_log/${date}_log.json`, JSON.stringify(data))

                this.file.resolveLocalFilesystemUrl(writeFile.nativeURL).then((entry:any) => {
                    entry.file(file => {
                        const reader = new FileReader();
                        reader.onloadend = async () => {
                            const blob = new Blob([reader.result], { type: file.type });
                            const formData = new FormData();
                            formData.append('log', blob, file.name);
                            let token:any = await this.ds.getStorage("X-Auth-Token")
                            if(token) {
                                let logEndpoint = this.ds.master_domain+'_endpoints/app/'+this.ds.api_version+'/store/upload-log';
                                let headers = new HttpHeaders ({
                                    'X-Auth-Token': token,
                                    'X-App-Version': this.ds.app_version.replace(/\./g,''),
                                    'X-Device-ID': this.ds.UniqueDeviceID,
                                });

                                this.ds.http.post(logEndpoint, formData, { headers }).toPromise().then((result:any)=>{
                                    res(result.file);
                                }).catch((err)=>{
                                    rej(false);
                                    console.error('err', err);
                                });
                            }
                        };
                        reader.readAsArrayBuffer(file);
                    })
                });
            }
        });
    }


}