import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'store-login',
    pathMatch: 'full'
  },
  {
    path: 'store-login',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/store-login/store-login.module').then( m => m.StoreLoginPageModule)
  },
  {
    path: 'manager-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/manager-dashboard/manager-dashboard.module').then( m => m.ManagerDashboardPageModule)
  },
  {
    path: 'associate-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/associate-dashboard/associate-dashboard.module').then( m => m.AssociateDashboardPageModule)
  },
  {
    path: 'form/:type',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/form/form.module').then( m => m.FormPageModule)
  },
  {
    path: 'employees',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/employees/employees.module').then( m => m.EmployeesPageModule)
  },
  {
    path: 'productivity-reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/productivity-reports/productivity-reports.module').then( m => m.ProductivityReportsPageModule)
  },
  {
    path: 'action-log',
    loadChildren: () => import('./pages/action-log/action-log.module').then( m => m.ActionLogPageModule)
  },
  {
    path: 'recent-forms',
    loadChildren: () => import('./pages/recent-forms/recent-forms.module').then( m => m.RecentFormsPageModule)
  },
  {
    path: 'search-active',
    loadChildren: () => import('./pages/search-active/search-active.module').then( m => m.SearchActivePageModule)
  },
  {
    path: 'search-completed',
    loadChildren: () => import('./pages/search-completed/search-completed.module').then( m => m.SearchCompletedPageModule)
  },
  {
    path: 'search-manual',
    loadChildren: () => import('./pages/search-manual/search-manual.module').then( m => m.SearchManualPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
