import { AlertController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogService } from '../log.service';
import { DataService } from './../../data.service';

const TOKEN_KEY = "X-Auth-Token";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authState$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private storage: Storage,
    private platform: Platform,
    private alertCtrl: AlertController,
    public ds: DataService,
    private log: LogService,
    private device: Device
  ) 
  { 
    this.platform.ready().then( _ => this.checkToken())
  }

  private checkToken() {
    this.storage.get(TOKEN_KEY).then(res =>this.authState$.next(res ? true : false))
  }

  public login(number:string) {
    number = number.toUpperCase();
    
    return new Promise(async (res, rej) => {
      
      //demo account
      if(number.startsWith('DEMO')) {
          this.ds.setStorage('trainingMode', 'true');
          this.ds.trainingMode = true;
          number = number.replace('DEMO', '');
      }

      if(this.ds.online) {
        let uuid:any, tablet_number:any = false;
        if(this.device && this.device.uuid) uuid = this.device.uuid;
        console.log("Logging in - check for tablet number", this.ds.tablet_number)
        await this.ds.checkTabletNumber(number, true);
        if(this.ds.tablet_number) tablet_number = true;

        this.ds.post('store/login', {number, uuid, new_tablet: !tablet_number}).then(async (result:any)=>{
          await this.ds.setStorage(TOKEN_KEY, result.store.token)
          await this.ds.setStorage('store', result.store);
          this.ds.store_number = result.store.store_number.toUpperCase();
          this.ds.store = result.store;

          if(result.tablet_number) await this.ds.setTabletNumber(result.tablet_number);
          res(result.store)
        }).catch((e)=>rej(e));
      }
      else {
        this.ds.getStorage('store').then((store:any)=>{
          if(number == store.store_number) {
            this.ds.store = store;
            this.ds.store_number = store.store_number;
            res(store);
            this.ds.setStorage(TOKEN_KEY, store.token).then(()=>res(store));
          }
          else {
            rej({success: false});
          }
        });
      }
    })
  }


  public logout() {
    return new Promise((res, rej) => {
        this.ds.removeStorage('managerLoginTime');
        this.ds.removeStorage('trainingMode');
        this.ds.removeStorage('selectedForm');
        this.ds.removeStorage('activeForm');
        this.ds.removeStorage(TOKEN_KEY);

        
        this.log.add("logout", this.ds.store_number)
    //   this.storage.get('language').then((data)=>{
    //     this.storage.clear().then(() => {
          this.authState$.next(false);
          this.ds.trainingMode = false;
    //       this.storage.set('language', data).then(()=>res());
    //     })
    //   })
    });
  }

  
  public async storeLock(header=false, message=false, cancel=false, continueButton=false) {
    return new Promise(async (res, rej) => {

      
      const alert = await this.alertCtrl.create({
        header: header ? header : this.ds.pageContent['associate-dashboard']['store_number_popup_title'],
        message: message ? message : this.ds.pageContent['associate-dashboard']['store_number_popup_message'],
        inputs: [
          {
            name: 'store_number',
            type: 'password',
          },
        ],
        buttons: [
          {
            text: cancel ? cancel : this.ds.pageContent['associate-dashboard']['store_number_popup_cancel'],
            role: 'cancel',
            cssClass: 'secondary'
          }, 
          {
            text: continueButton ? continueButton : this.ds.pageContent['associate-dashboard']['store_number_popup_continue'],
            handler: (alertData) => {
              let input = alertData.store_number.replace(/ /g,'').toUpperCase();
              let store_number = this.ds.store_number.replace(/ /g,'').toUpperCase();
              if(input == store_number) res(true);
              else rej({user_input: input})
            }
          }
        ]
      });
      await alert.present();
    });
  }
  
  public getAuthStateObserver(): Observable<boolean> {
    return this.authState$.asObservable();
  }

  public isAuthenticated() {
    return this.authState$.value;
  }
}