import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { AlertController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { File } from '@ionic-native/file/ngx';
import { Subject } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { LoadingService } from './core/loading.service';
import { environment } from 'src/environments/environment';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  api_version = 'v2';
  //start with NA as the default, to load languages and initial data on app open
  master_domain = 'https://naep.claires.app/';
  debug = true;
  currentView = 'associate';
  store_number;
  store:any = {}
  managerTimeLimit = 15;
  managerInterval;
  appPages = [];
  pageContent:any = {};
  trainingMode = false;
  online = true;
  connected = true;
  connectedInterval;
  app_version = '';
  tablet_number: any = 0;
  UniqueDeviceID:any = false;
  language:string = "";

  dateFormat = 'MMM D, YYYY';

  storeLogo = './assets/claires-we-make-memories-logo.png';
  
  private managerModelSource = new Subject();
  openManagerModel$ = this.managerModelSource.asObservable();
  
  private managerModelCloseSource = new Subject();
  closeManagerModal$ = this.managerModelCloseSource.asObservable();
  
  devMode = false;
  devModeCounter = 0;
  devModeTimer;

  constructor(
    public http: HttpClient,
    public file: File,
    public storage: Storage,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private router: Router,
    private uniqueDeviceID: UniqueDeviceID,
    private loader: LoadingService,
    private androidPermissions: AndroidPermissions
  ) {
    this.debug = !environment.production;
    this.getStorage('storeNumber').then(async (store_number:any)=>{
      if(store_number) {
        this.store_number = store_number;
        this.checkTabletNumber();
      }
    })
    this.getStorage('store').then((store:any)=>{
      if(store) {
        this.store = store;
        setTimeout(()=>this.updateStoreInfo(), 500);
      }
    })
    
    this.getStorage('language').then((data:any)=>{
        if(data.language) this.language = data.language
    })


    if (!this.connectedInterval) {
      let minutes = 3;
      this.connectedInterval = setInterval(()=>this.checkConnected(), (minutes * 60000));
    }
  }


  async getUniqueDeviceID() {
    await this.uniqueDeviceID.get()
      .then((uuid: any) => {
        console.log("loaded uuid",uuid);
        this.UniqueDeviceID = uuid;
      })
      .catch((error: any) => {
        console.error("loading uuid error ", error);
        this.UniqueDeviceID = `Error! ${error}`;
      });
  }

  getPermission(){
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE).then(res => {
      if(!res.hasPermission){
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE).then(res => {}).catch(error => {
          console.log("Error! Unable to request permission READ_PHONE_STATE "+error);
        });
      }
    }).catch(error => {
      console.log("Error! Unable to check permission READ_PHONE_STATE "+error);
    });
  }



  public async updateStoreInfo() {
    if(this.store.region_code) {
      if(['NA','WPS'].includes(this.store.region_code)) this.master_domain = 'https://naep.claires.app/';
      if(this.store.region_code == 'EU') this.master_domain = 'https://euep.claires.app/';
    }
    this.storeLogo = this.store.division === 'Icing' ? './assets/icing-logo.png' : './assets/claires-we-make-memories-logo.png';
    if(this.store.country != 'US') this.dateFormat = 'D MMM, YYYY';
  }


  public async setStorage(settingName, value, showLog=true){
    if(this.debug && showLog) console.log('setStorage - '+settingName, value);
    if(typeof value === 'object') value = JSON.stringify(value);
    return this.storage.set(`${ settingName }`, value);
  }

  public async getStorage(settingName, showLog=true){
    return new Promise((res, rej) => {
      this.storage.get(`${ settingName }`).then((data)=>{
        try {
            let parsed = JSON.parse(data);
            if (parsed && typeof parsed === "object") {
                if(this.debug && showLog) console.log('getStorage - '+settingName, parsed);
                return res(parsed);
            }
            else res(data);
        }
        catch (e) { res(data) }
      });
    })
  }
  public async removeStorage(settingName, showLog=true){
    if(this.debug && showLog) console.log('removeStorage - '+settingName)
    return await this.storage.remove(`${ settingName }`);
  }
  public async clearStorage(showLog=true) {
    if(this.debug && showLog) console.warn('clearStorage')
    return await this.storage.clear();
  }

  getHeaders(contentType:any='application/json') {
    return new Promise(async (resolve, reject) => {
      if(!this.UniqueDeviceID) await this.getUniqueDeviceID();
      if(!this.language) await this.getStorage('language');

      return this.getStorage("X-Auth-Token")
        .then((token:string)=>{
            let options = {
                'X-Auth-Token': token ? token : '',
                'X-App-Version': this.app_version.replace(/\./g,''),
                'X-Device-ID': this.UniqueDeviceID,
                'X-Tablet-Number': this.tablet_number,
                'X-App-Language': this.language
            }
            if(contentType) options['Content-Type'] = contentType;
            resolve({headers: new HttpHeaders (options)})
        })
        .catch(()=>resolve({
          headers: new HttpHeaders ({
            'X-Auth-Token': '',
            'Content-Type': contentType,
          })
        }))
    })
  }


  async getFormCount() {
    return new Promise(async(res, rej) => {
      let formCounts:any = await this.getStorage('formCounts');
      if(!formCounts) formCounts = {}
      if(formCounts[this.store_number]) {
        if(formCounts[this.store_number] === 99999) formCounts[this.store_number] = 1;
        else formCounts[this.store_number] += 1;
      }
      else formCounts[this.store_number] = 1
      await this.setStorage("formCounts", formCounts)
      res(formCounts[this.store_number])
    })
  }

  async setCurrentView (type) {
    if(this.debug) console.log('change view from ' + this.currentView + ' to ' + type)
    this.currentView = type;

    if(type === 'manager') {
      this.appPages = [
        {
          title: this.pageContent['app-menu']['manager_dashboard_link'],
          url: '/manager-dashboard',
          icon: 'home'
        },
        {
          title: this.pageContent['app-menu']['action_log_link'],
          url: '/action-log',
          icon: 'clipboard'
        },
        {
          title: this.pageContent['app-menu']['recent_forms_link'],
          url: '/recent-forms',
          icon: 'file-tray-full'
        },
        {
          title: this.pageContent['app-menu']['manager_employee_link'],
          url: '/employees',
          icon: 'people'
        },
        {
          title: this.pageContent['app-menu']['manager_productivity_link'],
          url: '/productivity-reports',
          icon: 'analytics'
        },
        {
          title: this.pageContent['app-menu']['search_active_forms_link'],
          url: '/search-active',
          icon: 'albums'
        },
        {
          title: this.pageContent['app-menu']['search_completed_forms_link'],
          url: '/search-completed',
          icon: 'albums'
        },
        // {
        //   title: this.pageContent['app-menu']['search_manual_forms_link'],
        //   url: '/search-manual',
        //   icon: 'albums'
        // },
      ]
    }
    else if(type === 'associate') {
      this.appPages = [
        {
          title: this.pageContent['app-menu']['associate_dashboard_link'],
          url: '/associate-dashboard',
          icon: 'home'
        },
        {
          title: this.pageContent['app-menu']['start_form_link'],
          url: '/form/new',
          icon: 'add-circle'
        }
      ]
    }
    else if(type === 'customer') {
      //change order depending on this.store.region_code
      this.appPages = [
        {
          title: this.pageContent['app-menu']['associate_form_link'],
          url: '/form/associate-form',
          icon: 'file-tray'
        }
      ];

      if(this.store.region_code != "EU") {
        this.appPages = this.appPages.concat([
          {
            title: this.pageContent['app-menu']['customer_form_link'],
            url: '/form/customer-form',
            icon: 'file-tray-full'
          },
          {
            title: this.pageContent['app-menu']['customer_declaration_link'],
            url: '/form/declaration',
            icon: 'document'
          }
        ])
      }
      else {
        this.appPages = this.appPages.concat([
          {
            title: this.pageContent['app-menu']['customer_declaration_link'],
            url: '/form/declaration',
            icon: 'document'
          },
          {
            title: this.pageContent['app-menu']['customer_form_link'],
            url: '/form/customer-form',
            icon: 'file-tray-full'
          }
        ])
      }


      this.appPages = this.appPages.concat([
        {
          title: this.pageContent['app-menu']['form_review'],
          url: '/form/review',
          icon: 'folder'
        },
        {
          title: this.pageContent['app-menu']['take_image_link'],
          url: '/form/take-image',
          icon: 'camera'
        },
        {
          title: this.pageContent['app-menu']['cancel_form_link'],
          url: '/form/cancel-form',
          icon: 'exit'
        },
      ]);
    }
    return this.setStorage('current-view', type);
  }


  public async checkTabletNumber(custom_store:any = false, request_new = false) {
    if(!custom_store) custom_store = this.store_number;
    if(!custom_store) {
      this.tablet_number = 0;
      return false;
    }
    
    console.log("checkTabletNumber", custom_store, request_new);
    let tablet_numbers:any = await this.getStorage('tablet_numbers')
    if(tablet_numbers && tablet_numbers[custom_store]) {
      console.warn("store tablet number is "+tablet_numbers[custom_store]);
      this.tablet_number = tablet_numbers[custom_store];
    }
    else {
      console.error('no tablet number set')
      if(request_new) {
        console.warn("Requesting new tablet number")
        this.get("store/get-tablet-number").then((data:any)=>{
          console.log('new tablet number', data.tablet_number)
          if(data.tablet_number) {
            this.setTabletNumber(data.tablet_number);
          }
        })
      }
    }
  }



  async setTabletNumber(tablet_number=false) {
    if(!this.store_number) return false;
    let tablet_numbers = await this.getStorage("tablet_numbers");
    if(!tablet_numbers) tablet_numbers = {};
    tablet_numbers[this.store_number] = tablet_number;
    this.tablet_number = tablet_number;
    this.setStorage("tablet_numbers", tablet_numbers)
  }



  public async editTabletNumber() {
    console.log('current tablet number', this.tablet_number);

    const alert = await this.alertCtrl.create({
      header: this.pageContent['app-menu'].set_tablet_number,
      inputs: [
        {
          name: 'tablet_number',
          type: 'number',
        },
      ],
      buttons: [
        {
          text: this.pageContent['app-menu'].cancel,
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.pageContent['app-menu'].set,
          handler: async ({tablet_number}) => {
            if(tablet_number) {
              this.loader.present();
              //check database if this store already has this number
              let res:any = await this.post("store/check-tablet-number", {new_number: tablet_number, current_number: this.tablet_number, overwrite: false});

              //if the new number already exists, prompt the user to confirm they want to overwrite the previous tablet number
              if(res.exists) {
                this.loader.dismiss();
                const confirmationAlert = await this.alertCtrl.create({
                  header: this.pageContent['app-menu'].tablet_number_set,
                  message: this.pageContent['app-menu'].confirm_tablet_overwrite,
                  buttons: [
                    {
                      text: this.pageContent['app-menu'].cancel,
                      role: 'cancel',
                      cssClass: 'secondary'
                    },
                    {
                      text: this.pageContent['app-menu'].confirm,
                      handler: async () => {
                        this.loader.present();
                        let res = await this.post("store/check-tablet-number", {new_number: tablet_number, current_number: this.tablet_number, overwrite: true})
                        await this.setTabletNumber(tablet_number)
                        this.loader.dismiss();
                      }
                    }
                  ]
                });
                confirmationAlert.present();
              }
              else {
                this.loader.dismiss();
                this.setTabletNumber(tablet_number)
              }
            }
            else {
              this.presentToast(this.pageContent['app-menu'].no_tablet_number_set)
              return false;
            }
          }
        }
      ]
    });
    await alert.present();



  }


  private getDemoData(endpoint) {
    return new Promise((res, rej) => {
      fetch('./assets/testData.json').then(res => res.json())
      .then(json => {
        let ep = endpoint.split('/');
        let retData = json[0];
        if(this.debug) console.log('demo request', endpoint)

        for(let item of ep) {
          if(retData){
            if(retData[item]) retData = retData[item];
            else {
              retData = false;
              if(this.debug) console.error('NO DEMO DATA FOR '+endpoint)
            }
          }
        }
        
        res(retData)
      });
    })
  }

  
  async presentToast(message='') {
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }
  
  async alert(message, header="Error") {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: this.pageContent['form']['okay_alert_button'],
          role: 'cancel',
          cssClass: 'secondary'
        }, 
      ]
    });
    await alert.present();
  }

  uptickDevMode() {
    this.devModeCounter++;
    if(!this.devModeTimer) {
        this.devModeTimer = setTimeout(()=>{
            this.devModeCounter = 0;
            clearTimeout(this.devModeTimer); 
            this.devModeTimer = null;
        }, 5000);
    }
    if(this.devModeCounter === 5) {
        this.devMode = !this.devMode;
        this.devModeCounter = 0;
        clearTimeout(this.devModeTimer); 
        this.devModeTimer = null;
    }
  }

  checkConnected() {
    this.get('page/health', 60000, true).then((data:any)=> {
      this.connected = data && data.success === true;
    }).catch(()=>this.connected = false)
  }

  async startManagerMode() {
    let originalView = this.currentView;
    await this.setCurrentView('manager')

    let today = new Date();
    await this.setStorage('managerLoginTime', today.getTime())

    if(originalView != 'manager') this.router.navigate(['manager-dashboard']);
    this.presentToast(this.pageContent['manager-dashboard']['manager_time_start']);
    this.managerInterval = setInterval(()=>this.checkManagerTime(), 60000)
  }


  endManagerMode() {
    if(this.debug) console.log('end manager mode');
    //trigger observable to close manager modal if open
    this.managerModelCloseSource.next();
    this.currentView = 'associate';
    this.setCurrentView('associate');
    this.removeStorage('managerLoginTime');
    clearInterval(this.managerInterval);
    this.router.navigate(['associate-dashboard']);
  }


  checkManagerTime() {
    this.getStorage('managerLoginTime').then((startTime:number)=>{
      if(startTime) {
        let today = new Date();
        let currentTime = today.getTime()
        let minutes = Math.floor(((currentTime - startTime)/1000)/60);

        if(minutes === this.managerTimeLimit - 1) this.presentToast(this.pageContent['manager-dashboard']['one_minute_remaining']);
        if(minutes === this.managerTimeLimit) this.managerModelSource.next();
        if((this.managerTimeLimit - minutes) < 0) this.endManagerMode();
      }
    })
  }


  getDataDump(load_new = false) {
    return new Promise(async (res, rej)=> {
      let date = new Date();
      let today = String(date.getMonth()+1).padStart(2,'0') + '-' + String(date.getDate()).padStart(2,'0') + '-' + date.getFullYear();
      
      let store_number = await this.getStorage('storeNumber');

      if(store_number) {
        let date = await this.getStorage('lastUpdated');
        let langData:any = await this.getStorage('language');
        
        if(this.debug) console.log('last updated', date, today);
        
        if((load_new || !date || date != today) && this.online) {
          //download data

          this.loader.dismiss();
          this.loader.present(this.pageContent['store-login']['downloading-data'] ? this.pageContent['store-login']['downloading-data'] : "Downloading Data");

          this.setStorage('lastUpdated', today)


          let languages = this.get('page/loadLanguages').then(({langList})=>{
            this.setStorage('languageList', langList)
          });

          let currency = this.get('page/loadCurrencyList').then(({currencyList})=>{
            this.setStorage('currencyList', currencyList)
          });

          let employees = this.get('employees/getPayrollList').then(({data})=>{
            this.setStorage('payrollList', data)
          });

          let skus = this.get('store/getSkuList').then(({data, descriptions})=>{
            this.setStorage('skuList', data)
            this.setStorage('skuDescriptionList', descriptions)
          });
          
          let forms = this.get("form/list").then(async ({forms})=>{
            await this.setStorage('formList', forms)
          });
          
          let manualForm = this.get("form/manual-form").then(async ({form})=>{
            await this.setStorage("manualForm", form)
          });

          let content = this.get('page/content/'+langData.language).then(async ({pageContent})=>{
            this.pageContent = pageContent;
            await this.setStorage('pageContent', this.pageContent)
          });

          //added 5/18/22 to try and speed up data dump
          Promise.all([languages, currency, employees, skus, forms, content, manualForm]).then((results)=>{
            this.loader.dismiss();
            res(true);
          }).catch((e)=>{
            console.error("failed to resolve all promises")
            rej("Unable to connect to database");
          })
        }
        else {
          if(this.debug) console.log("Data is up to date");

          if(this.online && langData.changed) {
            this.pageContent = await this.get('page/content/'+langData.language).then((res:any)=>res.pageContent)
            await this.setStorage('pageContent', this.pageContent)
            delete langData.changed;
            await this.setStorage('language', langData)
          }

          this.loader.dismiss();
          res(false);
        }
      }
      else {
        if(this.debug) console.log("can't get data dump - not logged in");
        rej(false);
      }
    })
  }

  async loadPDF(submission_id) {
    return this.getStorage("X-Auth-Token").then((token)=>`${this.master_domain}_endpoints/load/pdf/${submission_id}.pdf?X-Auth-Token=${token}`);
  }

  async loadFormImage(image_name, type='image') {
    return this.getStorage("X-Auth-Token").then((token)=>`${this.master_domain}_endpoints/load/${type}/${image_name}?X-Auth-Token=${token}`);
  }
  


  getPageContent(page) {
    return new Promise((res, rej) => {
      this.getStorage('pageContent').then((data)=>{
        if(data[page]) res(data[page]);
        else res({});
      })
    });
  }


  uploadImage(submission_id, imageData){
    return new Promise(async (res, rej)=>{
      this.file.resolveLocalFilesystemUrl(imageData).then((entry:any) => {
        entry.file(file => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const imgBlob = new Blob([reader.result], { type: file.type });
            const formData = new FormData();
            formData.append('submission_id', submission_id);
            formData.append('customer_image', imgBlob, file.name);
            this.getStorage("X-Auth-Token").then((token:string)=>{
              let headers = new HttpHeaders ({
                'X-Auth-Token': token,
                'X-App-Version': this.app_version.replace(/\./g,''),
                'X-Device-ID': this.UniqueDeviceID,
              });
              this.http.post(`${this.master_domain}_endpoints/app/${this.api_version}/form/upload-image${this.debug?'?debug=1':''}`, formData, {headers}).toPromise().then((dataRes)=>{
                if(this.debug) console.log("upload-image", dataRes)
                res(dataRes)
              }).catch((err)=>rej(err));
            }).catch((e)=>console.error('no token', e))
          };
          reader.readAsArrayBuffer(file);
        });
      })
    })
  }



  get(endpoint,timeoutAmount=60000, addRandom=false) {
    return new Promise((resolve, reject) => {
      if(this.currentView === 'demo') resolve(this.getDemoData(endpoint));
      else {
        this.getHeaders().then((httpOpts)=>{
          let request = `${this.master_domain}_endpoints/app/${this.api_version}/${endpoint}`;
          this.http.get(`${request}?${this.debug?'debug=1&':''}${addRandom?(Math.random() * 10):''}`, httpOpts)
          .pipe(
            timeout(timeoutAmount),
            tap((ev:any) => {
              // console.error("TAP triggered", ev)
              // console.error("TAP type ", ev.type)
              // if (ev.type === HttpEventType.Cancel) {
              //   // do something
              // }
            })
          ).subscribe((response) => {
            if(this.debug) console.log(endpoint, response);
            resolve(response);
          },
          (err) => {
            if(this.debug) console.error('get', endpoint, err);
            reject(err);
          });
        })
      }
    });
  }

  post(endpoint, postData) {
    return new Promise((resolve, reject) => {
      this.getHeaders().then((httpOpts)=>{
        let request = `${this.master_domain}_endpoints/app/${this.api_version}/${endpoint}`;
        this.http.post(`${request}?${this.debug?'debug=1&':''}`, JSON.stringify(postData), httpOpts).subscribe((response) => {
          if(this.debug) console.log(endpoint, response);
          resolve(response);
        }, err => {
          if(this.debug) console.error('post', request, err);
          // this.alert("Device is unable to connect to the server, please check your internet connection", "Unable to connect")
          reject(err);
        });
      });
    });
  }

}
