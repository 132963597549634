import { DataService } from 'src/app/data.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-manager-login',
  templateUrl: './manager-login.page.html',
  styleUrls: ['./manager-login.page.scss'],
})
export class ManagerLoginPage implements OnInit {

  storePassword;
  pageContent;

  constructor(
    private modalCtrl: ModalController, 
    private alertCtrl: AlertController, 
    public ds: DataService
  ) { }

  ngOnInit() {
    this.ds.getPageContent('manager-login').then((data)=>this.pageContent = data);
  }

  login() {
    this.ds.post('store/manager-login', {password: this.storePassword}).then(({messageVariable, success})=>{
      if(success) this.modalCtrl.dismiss({success: true});
      else this.presentAlert(this.pageContent[messageVariable])
    })
    .catch(({error, status})=>{
        if(status === 401) this.presentAlert(this.pageContent[error.messageVariable]);
        else this.presentAlert(this.pageContent['tablet_offline'])
    })
  }

  closeModal() {
    this.modalCtrl.dismiss({success: false});
  }
  
  async presentAlert(message, title='') {
    if(title === '') title = this.pageContent['alert_title'];

    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: ['OK']
    });
    await alert.present();
  }

}
